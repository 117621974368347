.holder {
  width: 25%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.holder:hover {
  background-color: lightgray;
}
.thumbnail {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
* {
  color: black;
  padding: 0px;
  margin: 0px;
}
.para {
  width: 100%;
}