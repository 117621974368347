.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
    border-bottom: 1px solid #e9ecef;
}
.backandtitle {
    display: flex;
    align-items: center;
}
.title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding: 1rem;
}
.logo {
    width: 3rem;
    height: 3rem;
    border-radius: 8px;
    margin-right: 1rem;
}
.pdfView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}
.back {
    color: black !important;
}