.title {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.pfp {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin: 15px;
}
.pfpButton {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
}
.logo1 {
  margin-left: 75px;
  margin-top: 15px;
  width: 100px;
  height: 100px;
  border-radius: 25px;
}
.signout {
  margin-top: 10px;
  margin-left: 8px;
}
.classDiv {
  padding: 0px;
  width: calc(94%/3);
  margin: 1%;
  aspect-ratio: 10/7;
  border-radius: 15px;
  border-width: 0px;
  display: flex;
  background-color: white;
  flex-direction: column;
}
.classDiv4 {
  padding: 0px;
  width: calc(96%/2);
  margin: 1%;
  aspect-ratio: 10/7;
  border-radius: 15px;
  border-width: 0px;
  display: flex;
  background-color: white;
  flex-direction: column;
}
.classDiv:hover {
  background-color: #f0f0f0;
}
.classDiv4:hover {
  background-color: #f0f0f0;
}
.classImage {
  margin: 0px;
  width: 100%;
  height: 80%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.holder {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: baseline;
}
.logo2 {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin: 15px;
}
.logoHolder {
  display: flex;
  flex-direction: row;
  width: 40%;
}
.titleText {
  font-size: 2em;
  font-weight: bolder;
  align-self: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.buttonText {
  font-size: 3em;
  font-weight: bolder;
  align-self: center;
  font-family: Roboto, sans-serif;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
* {
  color: black;
}