.headImg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.plus {
  background-color: #007AFF !important;
  width: 90px !important;
  height: 90px !important;
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: 15px !important;
}
.titleHolder {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lineHolder{
  height: 50px;
  width: 100%;
  border-color: grey;
  border-width: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.line {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}
.icon{
  width: 50% !important;
  height: 50% !important;
}
.noteHolder{ 
  margin: 1%;
  display: flex;
  flex-direction: column;
}
.textField{
  margin-left: 10px !important;
  margin-right: 10px !important;
}
iframe {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 160px;
  object-fit: contain;
}
h1 {
  margin: 10px;
}
h2 {
  margin: 10px;
}
p {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
}
* {
  color: black;
  padding: 0px;
  margin: 0px;
}